import plane from '../img/icon-sending-email.svg';
import contacts from '../img/icon-contacts.svg';
import analytics from '../img/icon-analytics.svg';
import account from '../img/icon-account.svg';

const HOME_CARDS = [
  {
    ICON: plane,
    TITLE: 'Updates',
    LINKS: [
      { URL: '/ui/sending-email/how-to-send-email-with-marketing-campaigns/', TEXTNODE: 'Sending Email with Marketing Campaigns' },
      { URL: '/ui/sending-email/editor/', TEXTNODE: 'The Design & Code Editor' },
      { URL: '/ui/sending-email/how-to-send-an-email-with-dynamic-transactional-templates/', TEXTNODE: 'Sending email with Dynamic Transactional Templates' },
    ],
    COPY: 'We annually review and update analytical data and Platform features to ensure users have the best experience.',
    BUTTON_LINK: '/release-notes/',
  },
  {
    ICON: contacts,
    TITLE: 'User Guide',
    LINKS: [
      { URL: '/ui/managing-contacts/create-and-manage-contacts/', TEXTNODE: 'Create and Manage Contacts' },
      { URL: '/ui/managing-contacts/building-your-contact-list/', TEXTNODE: 'Contacts Best Practices' },
      { URL: '/ui/managing-contacts/segmenting-your-contacts/', TEXTNODE: 'Segmenting Contacts' },
    ],
    COPY: 'A technical communication document intended to assist Platform model users',
    BUTTON_LINK: '/ui/user-guide/',
  },
  {
    ICON: analytics,
    TITLE: 'Whitepapers',
    LINKS: [
      { URL: '/ui/analytics-and-reporting/email-activity-feed/', TEXTNODE: 'The Email Activity Feed' },
      { URL: '/ui/analytics-and-reporting/marketing-campaigns-stats/', TEXTNODE: 'Marketing Campaigns Statistics' },
      { URL: '/ui/analytics-and-reporting/stats-overview/', TEXTNODE: 'Statistics Overview' },
    ],
    COPY: 'Risk-Enterprise\'s analytical papers on topics that present our systematic approach and analysis',
    BUTTON_LINK: '/ui/whitepapers/list',
  },
  {
    ICON: account,
    TITLE: 'FAQ',
    LINKS: [
      { URL: '/ui/account-and-settings/api-keys/', TEXTNODE: 'API Keys' },
      { URL: '/ui/account-and-settings/resetting-your-username-and-password/', TEXTNODE: 'Resetting Your Username and Password' },
      { URL: '/ui/account-and-settings/how-to-set-up-domain-authentication/', TEXTNODE: 'How to set up Domain Authentication' },
    ],
    COPY: 'A list of frequently asked questions and answers',
    BUTTON_LINK: '/ui/account-and-settings/',
  },
];

export default HOME_CARDS;
