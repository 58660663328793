const HOME_CALLOUTS = [
  {
    BADGE: 'corporate',
    TITLE: 'Corporate PD',
    TAG: '',
    COPY:
      'This document is intended as a handbook for assisting users with the application of Risk Enterprise’s Corporate PD methodology for the assessment of the PD (probability of default) credit risk component of corporate firms.',
    LINK: '/ui/model-documentation/corporate-pd-documentation/',
  },
  {
    BADGE: 'corporate',
    TITLE: 'Corporate LGD',
    TAG: '',
    COPY:
        'This document is intended as a handbook for assisting users with the application of Risk Enterprise’s Corporate LGD methodology for the assessment of the LGD (loss given default) credit risk component of corporate firms.',
    LINK: '/ui/model-documentation/corporate-lgd-documentation/',
  },
  {
    BADGE: 'bank',
    TITLE: 'Bank PD',
    TAG: '',
    COPY:
        'Commercial Banks – Assessment of Default Risk (PD) – User Handbook',
    LINK: '/ui/model-documentation/bank-pd-documentation/',
  },
  {
    BADGE: 'ifrs 9',
    TITLE: 'ECL',
    TAG: '',
    COPY:
        'IFRS9 Impairment Requirements – Financial Instruments Handbook',
    LINK: '/ui/model-documentation/ecl-documentation/',
  },
]

export default HOME_CALLOUTS;
